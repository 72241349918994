<template>
  <b-container class="my-4">
    <h3 class="mb-4">{{ $t('nav.adv') }}</h3>
    <p v-html="$t('adv_desc')"></p>
    <b-row class="my-4">
      <b-col cols="12" sm="6" lg="4" xl="2">
        <div class="info">
          <div class="info__heading">{{ $t('phones') }}:</div>
          <a href="tel:+998712314881" class="info__content">
            +998 71 231-48-81
          </a>
          <a href="tel:+977542076" class="info__content">
            +998 97 754-20-76
          </a>
        </div>
      </b-col>
      <b-col cols="12" sm="6" lg="4" xl="2" class="mt-4 mt-sm-0">
        <div class="info">
          <div class="info__heading">{{ $t('email') }}</div>
          <a href="mailto:reklama@postda.uz" class="info__content">
            reklama@postda.uz
          </a>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: this.$t('nav.adv')
    }
  }
}
</script>
